<template>
  <label class="select">
    <select :name="name" :id="inputId" :class="classList">
      <option v-for="item in options" :key="item.id" :value="item.value">
        {{ item.text }}
      </option>
    </select>

    <slot></slot>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    inputId: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    classList: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style lang="scss">
label.select {
  border-radius: 14px;
  background: rgba($color: #a3a3a3, $alpha: 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  position: relative;

  select {
    background: transparent;
    border-radius: 0px;
    padding: 11px 17px 11px 17px;
    border: none;
    width: fit-content;
    font-size: 22px;
    color: #5828ff;
    font-family: "pm";
    min-height: 52px;
    cursor: pointer;

    &.has-icon {
      padding: 11px 40px 11px 17px;
    }

    &.date {
      max-width: 195px;
    }
  }

  svg {
    position: absolute;
    right: 15px;
    pointer-events: none;
  }
}
</style>
