
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    testStatistics: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const url = computed(() => {
      if (props.testStatistics['targetsite-url']) {
        return props.testStatistics['targetsite-url'].replace(/(^\w+:|^)\/\//, '');
      }

      return '...loading';
    });

    return { url };
  },
});
