<template>
  <canvas ref="passFailChartElement"></canvas>
</template>

<script lang="ts">
import {
  defineComponent, ref, onMounted, PropType, computed,
} from 'vue';
import { ItestStatistics } from '@/types';
import { newDoughnutChartObject } from '../../../../helpers/charts';

export default defineComponent({
  name: 'ChartPassFail',
  props: {
    testStatistics: {
      type: Object as PropType<ItestStatistics>,
      required: true,
    },
  },
  setup(props) {
    const passFailChartElement = ref(null);
    const passFailChart = computed(() => newDoughnutChartObject(passFailChartElement.value));

    const updateDataInChart = (data: ItestStatistics): void => {
      passFailChart.value.data.datasets[0].data = [
        data['results-ok'],
        data['results-fail'],
      ];
      passFailChart.value.update();
    };

    onMounted(() => {
      updateDataInChart(props.testStatistics);
    });

    return { passFailChartElement };
  },
});
</script>

<style lang="scss" scoped>
</style>
