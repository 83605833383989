
import {
  defineComponent, ref, onMounted, PropType, computed,
} from 'vue';
import { ItestResult } from '@/types';
import { convertDate, convertMsToSec } from '@/helpers/mixins';
import { newLinearChartObject } from '../../../../helpers/charts';

export default defineComponent({
  name: 'ChartPerformance',
  props: {
    testResults: {
      type: Array as PropType<ItestResult[]>,
      required: true,
    },
  },
  setup(props) {
    const performanceChartElement = ref(null);
    const performanceChart = computed(() => newLinearChartObject(performanceChartElement.value));

    const updateDataInChart = (data: Array<ItestResult>): void => {
      performanceChart.value.data.datasets[0].data = data
        ?.map((e) => e.duration)
        .slice(0, 10)
        .reverse().map(
          (e: number) => convertMsToSec(e),
        );
      performanceChart.value.data.labels = data
        ?.map((e) => convertDate(e.dateUx))
        .slice(0, 10)
        .reverse();
      performanceChart.value.options.scales.x.grid.display = false;
      performanceChart.value.options.scales.x.title.display = true;
      performanceChart.value.options.scales.x.title.text = 'Date';
      performanceChart.value.options.scales.y.title.text = 'Time (s)';
      performanceChart.value.options.scales.y.title.display = true;
      performanceChart.value.update();
    };

    onMounted(() => {
      updateDataInChart(props.testResults);
    });

    return { performanceChartElement };
  },
});
