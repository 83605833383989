<template>
  <the-box type="row">
    <div class="small-box">
      <span>{{ websiteName }}</span>
    </div>

    <div class="small-box">
      <span>Rating</span>
      <span ref="durationPercentileElement">
        <img src="~@/assets/img/admin/rocket.svg" alt="rocket" />
        <i>{{ durationPercentile }}%</i>
      </span>

      <span ref="failPercentileElement">
        <img src="~@/assets/img/admin/user.svg" alt="user" />
        <i>{{ failPercentile }}%</i>
      </span>
      <!-- <span><img src="~@/assets/img/admin/x-mark.svg" alt="x-mark"> <i>99%</i> </span> -->
    </div>

    <div class="small-box">
      <span>
        <img src="~@/assets/img/admin/laptop.svg" alt="laptop" /> Total runs:
        <i>{{ testStatistics["results-total"] }}</i>
      </span>
    </div>
  </the-box>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent, computed, PropType, ref, onMounted,
} from 'vue';
import { ItestStatistics } from '@/types';
import TheBox from '@/components/ui/TheBox.vue';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import psl from 'psl';

export default defineComponent({
  components: { TheBox },
  props: {
    testStatistics: {
      type: Object as PropType<ItestStatistics>,
      required: true,
    },
  },
  setup(props) {
    const durationPercentileElement = ref(document.createElement('div'));
    const failPercentileElement = ref(document.createElement('div'));

    function getPercentile(averageValue: number, globalValues: string[], caller: 'fail' | 'duration') {
      const reducer = (prev: any, curr: any) => (Math.abs(curr - averageValue) < Math.abs(prev - averageValue)
        ? curr
        : prev);
      const closestValue = globalValues.reduce(reducer);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const getKeyByValue = (object: ItestStatistics, value: any) => Object.keys(object)
        .filter((key) => key.includes('global-results-'))
        .find((key) => object[key] === value)!
        .replace(/\D/g, '');

      if (caller === 'duration') {
        return 100 - parseInt(getKeyByValue(props.testStatistics, closestValue), 10);
      }
      return (100 - parseInt(getKeyByValue(props.testStatistics, closestValue), 10));
    }

    function getAllKeys() {
      return Object.values(props.testStatistics);
    }

    const websiteName = computed(() => {
      if (props.testStatistics['targetsite-url'] !== undefined) {
        const { hostname } = new URL(props.testStatistics['targetsite-url']);
        return psl.parse(hostname).sld;
      }
      return 'none';
    });

    const durationPercentile = computed(() => {
      const averageDuration = props.testStatistics['results-average-duration'];
      const globalDurationValues: any = getAllKeys().slice(24, 33);
      return getPercentile(averageDuration, globalDurationValues, 'duration');
    });

    const failPercentile = computed(() => {
      const averageFail = (props.testStatistics['results-fail'] / props.testStatistics['results-total']);
      const globalFailValues: any = getAllKeys().slice(33, 42);
      return getPercentile(averageFail, globalFailValues, 'fail');
    });

    onMounted(() => {
      tippy(durationPercentileElement.value, {
        content:
          `<span style="color:white;font-size:13px;">Your site performs better than ${durationPercentile.value}% of WordPress sites.</span>`,
        allowHTML: true,
      });
      tippy(failPercentileElement.value, {
        content:
          `<span style="color:white;font-size:13px;">Your site has better fail ratio than ${failPercentile.value}% of WordPress sites.</span>`,
        allowHTML: true,
      });
    });

    return {
      websiteName,
      durationPercentile,
      failPercentile,
      durationPercentileElement,
      failPercentileElement,
    };
  },
});
</script>

<style lang="scss" scoped>
.content {
  [class*="box"] {
    padding: 70px 45px;

    @media (max-width: 988px) {
      grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
      grid-auto-flow: dense;
      row-gap: 30px;
      padding: 30px 25px;
    }
    @media (max-width: 530px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .small-box {
      background-color: #f0f1f2;
      padding: 22px 26px;
      border-radius: 14px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: "pm-b";

      @media (max-width: 500px) {
        row-gap: 10px;
      }

      span {
        font-size: 22px;
        font-family: "pm-b";
        color: #5828ff;

        @media (max-width: 500px) {
          flex-basis: 100%;
        }

        img {
          vertical-align: middle;
          margin: 0px 5px;
        }

        i {
          font-size: 19px;
          font-family: "pm";
          text-decoration: none;
          text-transform: none;
          color: #807cf4;
          font-style: normal;
        }
      }
    }
  }
}
</style>
