// ? /public/wpusertestresults/{siteToken} and use header for security token

import router from '@/router';
import axios from 'axios';
import notify from '@/helpers/notify';
import store from '@/store/index';

const instance = axios.create({
  baseURL: 'https://api.d2h5.com/v1/public/',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const getSiteToken = () => {
  if (router.currentRoute.value.query.s) {
    return router.currentRoute.value.query.s.toString();
  }
  notify.error('Site token was not provided');
  throw new Error('site token not provided');
};

const getSecurityTokenFromStore = (): string => store.getters.getSecurityToken;

const useApi = () => {
  const postRequestOnApi = (endpoint: string, includeToken: boolean, includeHeader: boolean, options?: any) => {
    const fullOptions = {
      ip: '52.28.112.131',
      site_token: includeToken ? getSiteToken() : '',
      ...options,
    };
    const header = {
      headers: {
        securitytoken: getSecurityTokenFromStore(),
      },
    };

    return instance.post(endpoint, fullOptions, includeHeader ? header : {});
  };

  const getRequestOnApi = (endpoint: string) => instance.get(`${endpoint}/${getSiteToken()}`, {
    headers: {
      securitytoken: getSecurityTokenFromStore(),
    },
  });

  const getImageData = (id: string) => instance.get(`wpusertestresultimage/${getSiteToken()}/${id}`, {
    headers: {
      securitytoken: getSecurityTokenFromStore(),
    },
  });

  async function fetchSiteInformation(siteToken: string) {
    const resultData = await Promise.all([
      postRequestOnApi(`wpusertestresults/${siteToken}`, false, true, {
        testResultOrders: [
          {
            testResultOrderDirection: 'DESCENDING',
            testResultOrderField: 'DATE',
          },
        ],
      }),
      getRequestOnApi('wpusertestresultsstatistics'),
    ]);
    return resultData;
  }

  const getSiteData = () => Promise.resolve(postRequestOnApi('wpusertokens', true, false))
    .then(((result) => {
      store.commit('setSecurityToken', result.data.token);
      return fetchSiteInformation(getSiteToken());
    }))
    .catch(() => {
      notify.error('Unexpected error happened while getting data');
      throw new Error('unexpected error happened while getting data');
    });

  return {
    postRequestOnApi, getRequestOnApi, getImageData, getSiteData,
  };
};

export default useApi;
