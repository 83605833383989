<template>
  <div class="statistics-graphs-row small-section">
    <div class="box">
      <span class="title">Your Website Performance</span>
      <chart-performance :testResults="filteredResults" />
    </div>
    <div class="box">
      <span class="title">
        Pass / Fail ratio ({{ testStatistics["results-ok"] }} /
        {{ testStatistics["results-fail"] }})
      </span>
      <chart-pass-fail :testStatistics="testStatistics" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { ItestResult } from '@/types';
import ChartPerformance from './charts/ChartPerformance.vue';
import ChartPassFail from './charts/ChartPassFail.vue';

export default defineComponent({
  components: { ChartPerformance, ChartPassFail },
  name: 'DashboardGraphs',
  props: {
    testResults: {
      type: Array as PropType<ItestResult[]>,
      required: true,
    },
    testStatistics: Object,
  },
  setup(props) {
    const filteredResults = computed(() => props.testResults.filter((e: ItestResult) => e.status === 'DONE_OK'));

    return { filteredResults };
  },
});
</script>

<style lang="scss" scoped>
.statistics-graphs-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
  gap: 23px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  .box {
    padding: 40px;

    canvas {
      margin-top: 80px;

      @media (max-width: 600px) {
        margin-top: 30px;
      }
    }
  }
}
</style>
