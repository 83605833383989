
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent, computed, PropType, ref, onMounted,
} from 'vue';
import { ItestStatistics } from '@/types';
import TheBox from '@/components/ui/TheBox.vue';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import psl from 'psl';

export default defineComponent({
  components: { TheBox },
  props: {
    testStatistics: {
      type: Object as PropType<ItestStatistics>,
      required: true,
    },
  },
  setup(props) {
    const durationPercentileElement = ref(document.createElement('div'));
    const failPercentileElement = ref(document.createElement('div'));

    function getPercentile(averageValue: number, globalValues: string[], caller: 'fail' | 'duration') {
      const reducer = (prev: any, curr: any) => (Math.abs(curr - averageValue) < Math.abs(prev - averageValue)
        ? curr
        : prev);
      const closestValue = globalValues.reduce(reducer);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const getKeyByValue = (object: ItestStatistics, value: any) => Object.keys(object)
        .filter((key) => key.includes('global-results-'))
        .find((key) => object[key] === value)!
        .replace(/\D/g, '');

      if (caller === 'duration') {
        return 100 - parseInt(getKeyByValue(props.testStatistics, closestValue), 10);
      }
      return (100 - parseInt(getKeyByValue(props.testStatistics, closestValue), 10));
    }

    function getAllKeys() {
      return Object.values(props.testStatistics);
    }

    const websiteName = computed(() => {
      if (props.testStatistics['targetsite-url'] !== undefined) {
        const { hostname } = new URL(props.testStatistics['targetsite-url']);
        return psl.parse(hostname).sld;
      }
      return 'none';
    });

    const durationPercentile = computed(() => {
      const averageDuration = props.testStatistics['results-average-duration'];
      const globalDurationValues: any = getAllKeys().slice(24, 33);
      return getPercentile(averageDuration, globalDurationValues, 'duration');
    });

    const failPercentile = computed(() => {
      const averageFail = (props.testStatistics['results-fail'] / props.testStatistics['results-total']);
      const globalFailValues: any = getAllKeys().slice(33, 42);
      return getPercentile(averageFail, globalFailValues, 'fail');
    });

    onMounted(() => {
      tippy(durationPercentileElement.value, {
        content:
          `<span style="color:white;font-size:13px;">Your site performs better than ${durationPercentile.value}% of WordPress sites.</span>`,
        allowHTML: true,
      });
      tippy(failPercentileElement.value, {
        content:
          `<span style="color:white;font-size:13px;">Your site has better fail ratio than ${failPercentile.value}% of WordPress sites.</span>`,
        allowHTML: true,
      });
    });

    return {
      websiteName,
      durationPercentile,
      failPercentile,
      durationPercentileElement,
      failPercentileElement,
    };
  },
});
