<template>
  <header>
    <div class="content">
      <img src="~@/assets/img/admin/logo-white.svg" alt="logo">

      <div class="right">
        <span class="name">Target URL: {{url}}</span>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    testStatistics: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const url = computed(() => {
      if (props.testStatistics['targetsite-url']) {
        return props.testStatistics['targetsite-url'].replace(/(^\w+:|^)\/\//, '');
      }

      return '...loading';
    });

    return { url };
  },
});
</script>

<style lang="scss" scoped>
  header {
    width: 100%;
    height: 250px;
    background: transparent linear-gradient(176deg, #A5A2F6 0%, #5828FF 100%) 0% 0% no-repeat padding-box;

    .content{
      display: flex;
      height: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .right{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        .name{
          color: white;
          font-size: 23px;
          margin-right: 20px;
          font-family: "pm-b";
        }
      }
    }
  }
</style>
