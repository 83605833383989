<template>
  <the-header :testStatistics="testStatistics"></the-header>
  <admin-dashboard
    :testStatistics="testStatistics"
    :testResults="testResults"
    v-if="dataFetched"
  />
  <the-loading v-if="!dataFetched"></the-loading>
</template>

<script lang="ts">
import {
  defineComponent, onUnmounted, reactive, toRefs, ref,
} from 'vue';
import { ItestResults, ItestStatistics } from '@/types';
import TheHeader from './TheHeader.vue';
import TheLoading from '../../components/ui/TheLoading.vue';
import AdminDashboard from './AdminDashboard.vue';
import ApiHandler from '../../helpers/apiHandler';

interface Data {
  testStatistics: ItestStatistics | boolean;
  testResults: ItestResults;
  totalTestsNumber: number;
}

export default defineComponent({
  components: {
    TheHeader,
    AdminDashboard,
    TheLoading,
  },
  setup() {
    const api = ApiHandler();
    const mainContainer = document.querySelector('main');
    const dataFetched = ref(false);

    const data = reactive<Data>({
      testStatistics: false,
      testResults: [],
      totalTestsNumber: 0,
    });

    if (mainContainer) {
      mainContainer.className = 'container admin';
    }

    onUnmounted(() => {
      if (mainContainer) {
        mainContainer.className = 'container';
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    api.getSiteData().then((webData: any) => {
      const [testResults, testResultsStatistics] = webData;
      data.testStatistics = testResultsStatistics.data;
      // eslint-disable-next-line no-underscore-dangle
      data.testResults = testResults.data._embedded.testResults;
      // eslint-disable-next-line no-underscore-dangle
      data.totalTestsNumber = testResults.data._embedded.testResults.length;

      dataFetched.value = true;
    });

    return { ...toRefs(data), dataFetched };
  },
});
</script>

<style lang="scss">
main.admin {
  background: #f8f8f8;
}
</style>
