<template>
  <section class="dashboard">
    <div class="content">
      <dashboard-general-data
        :testStatistics="testStatistics"
      ></dashboard-general-data>

      <dashboard-graphs
        :testResults="testResults"
        :testStatistics="testStatistics"
      >
      </dashboard-graphs>

      <dashboard-data-table :testResults="testResults"> </dashboard-data-table>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardDataTable from './dashboard/table/TableRuns.vue';
import DashboardGraphs from './dashboard/DashboardGraphs.vue';
import DashboardGeneralData from './dashboard/DashboardGeneralData.vue';

export default defineComponent({
  name: 'AdminDashboard',
  components: { DashboardDataTable, DashboardGraphs, DashboardGeneralData },
  props: {
    testStatistics: {
      type: Object,
      required: true,
    },
    testResults: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style lang="scss">
section.dashboard {
  padding: 75px 0;

  .small-section {
    margin-top: 45px;
  }

  span.title {
    font-size: 30px;
    font-family: "pm-b";
    color: #5828ff;
  }
}
</style>
