<template>
  <div class="loading">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  margin: 0 auto;
  width: 50px;
  position: relative;
  height: 200px;
  display: flex;
  align-items: center;
}
.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #4b9cdb;
}

@keyframes loadingCircle {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.loading .line:nth-child(1) {
  animation: loadingCircle 0.6s 0.1s linear infinite;
}
.loading .line:nth-child(2) {
  animation: loadingCircle 0.6s 0.2s linear infinite;
}
.loading .line:nth-child(3) {
  animation: loadingCircle 0.6s 0.3s linear infinite;
}
</style>
