
import { defineComponent } from 'vue';
import DashboardDataTable from './dashboard/table/TableRuns.vue';
import DashboardGraphs from './dashboard/DashboardGraphs.vue';
import DashboardGeneralData from './dashboard/DashboardGeneralData.vue';

export default defineComponent({
  name: 'AdminDashboard',
  components: { DashboardDataTable, DashboardGraphs, DashboardGeneralData },
  props: {
    testStatistics: {
      type: Object,
      required: true,
    },
    testResults: {
      type: Array,
      required: true,
    },
  },
});
