
import { computed, defineComponent, PropType } from 'vue';
import { ItestResult } from '@/types';
import ChartPerformance from './charts/ChartPerformance.vue';
import ChartPassFail from './charts/ChartPassFail.vue';

export default defineComponent({
  components: { ChartPerformance, ChartPassFail },
  name: 'DashboardGraphs',
  props: {
    testResults: {
      type: Array as PropType<ItestResult[]>,
      required: true,
    },
    testStatistics: Object,
  },
  setup(props) {
    const filteredResults = computed(() => props.testResults.filter((e: ItestResult) => e.status === 'DONE_OK'));

    return { filteredResults };
  },
});
