/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from 'chart.js/auto';

function handleResize(chart: any) {
  if (window.innerWidth <= 1092) {
    chart.options.aspectRatio = 2;
  }
  if (window.innerWidth > 1092) {
    chart.options.aspectRatio = 1.5;
  }
  if (window.innerWidth < 636) {
    chart.options.aspectRatio = 1.5;

    if (chart.options.type === 'doughnut') {
      chart.options.cutout = 50;
    }
  }
  if (window.innerWidth < 500) {
    chart.options.aspectRatio = 1;
  }
}

const newLinearChartObject: Chart = (element: any) => {
  const options = {
    type: 'line',
    data: {
      labels: '',
      datasets: [
        {
          label: 'X - date, Y - time (s)',
          data: '',
          cubicInterpolationMode: 'monotone',
          borderColor: '#9E2CFF',
          borderWidth: 1,
        },
      ],
    },
    options: {
      aspectRatio: 1.5,
      responsive: true,
      onResize(chart: any) {
        handleResize(chart);
      },
      plugins: {
        legend: {
          display: false,
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time (s)',
            },
          },
        },
      },
    },
  };

  return new Chart(element, options);
};

const newDoughnutChartObject: Chart = (element: any) => {
  const options = {
    type: 'doughnut',
    data: {
      labels: ['Pass', 'Fail'],
      datasets: [
        {
          data: '',
          backgroundColor: ['#A5A2F6', '#969CA2'],
          borderWidth: 1,
        },
      ],
    },
    options: {
      aspectRatio: 1.5,
      responsive: true,
      onResize(chart: any) {
        handleResize(chart);
      },
      plugins: {
        legend: {
          position: 'right',
          onClick() {
            return '';
          },
        },
      },
      cutout: 95,
    },
  };

  return new Chart(element, options);
};

export { newLinearChartObject, newDoughnutChartObject };
