import dayjs from 'dayjs';

const convertDate = (date: number): string => dayjs(date * 1000).format('YYYY-MM-DD');

const convertStatus = (status: string): string => {
  if (status === 'DONE_OK') return 'OK';
  if (status === 'DONE_FAIL') return 'FAIL';
  return 'error';
};

const convertMsToSec = (time: number): string => (time / 1000).toFixed(2);

export { convertDate, convertStatus, convertMsToSec };
