
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    inputId: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    classList: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
  },
});
