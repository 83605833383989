
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, reactive } from 'vue';
import AppInput from '@/components/ui/native/AppInput.vue';
import AppSelect from '@/components/ui/native/AppSelect.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import IconCalendar from '@/components/icons/IconCalendar.vue';

type Data = {
  status: boolean | undefined;
  dateFrom: number;
  dateTo: number;
};

export default defineComponent({
  components: {
    AppInput,
    AppSelect,
    IconArrow,
    IconCalendar,
  },
  emits: ['filterResults'],
  setup(props, { emit }) {
    const data = reactive<Data>({
      status: undefined,
      dateFrom: 0,
      dateTo: Date.now(),
    });

    const statusOptions = [
      {
        value: 'all',
        text: 'Status',
      },
      {
        value: 'pass',
        text: 'Pass',
      },
      {
        value: 'fail',
        text: 'Fail',
      },
    ];

    function filterResults() {
      const { status, dateFrom, dateTo } = data;
      emit('filterResults', status, dateFrom, dateTo);
    }

    function resetFilter() {
      data.status = undefined;
      data.dateFrom = 0;
      data.dateTo = Date.now();
      filterResults();
    }

    function changeStatus(event: any) {
      if (event.target.value === 'pass') {
        data.status = true;
      } else if (event.target.value === 'fail') {
        data.status = false;
      } else if (event.target.value === 'all') {
        data.status = undefined;
      }
      filterResults();
    }

    function changeDateFrom(event: any) {
      // eslint-disable-next-line no-underscore-dangle
      data.dateFrom = event.target._flatpickr.latestSelectedDateObj.getTime() / 1000;
      filterResults();
    }

    function changeDateTo(event: any) {
      // eslint-disable-next-line no-underscore-dangle
      data.dateTo = event.target._flatpickr.latestSelectedDateObj.getTime() / 1000;
      filterResults();
    }

    return {
      filterResults,
      changeStatus,
      changeDateFrom,
      changeDateTo,
      resetFilter,
      statusOptions,
    };
  },
});
