
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  defineComponent, reactive, toRefs, onMounted, PropType,
} from 'vue';
import { useStore } from 'vuex';
import * as _ from 'lodash';
import { Notyf } from 'notyf';
import { ItestResults, ItestResult } from '@/types';
import { convertDate, convertStatus, convertMsToSec } from '@/helpers/mixins';
import TheButton from '@/components/ui/native/AppButton.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import TheBox from '@/components/ui/TheBox.vue';
import TableFilter from './TableFilter.vue';
import useApi from '../../../../helpers/apiHandler';
import 'notyf/notyf.min.css';

interface Data {
  displayMoreTestResultsBy: number;
  displayedResults: ItestResults;
  filteredResults: ItestResults;
  isLoadMoreButtonActive: boolean;
  imageBinaryData: string;
  currentlyDisplayed: number
}

export default defineComponent({
  components: {
    TheButton,
    TheBox,
    TableFilter,
    IconArrow,
  },
  emits: ['imageLoaded'],
  props: {
    testResults: {
      type: Array as PropType<ItestResults>,
      required: true,
    },
  },
  setup(props) {
    const data = reactive<Data>({
      displayMoreTestResultsBy: 5,
      displayedResults: [],
      filteredResults: [],
      isLoadMoreButtonActive: true,
      imageBinaryData: '',
      currentlyDisplayed: 0,
    });

    const api = useApi();
    const store = useStore();
    const nf = new Notyf();
    const getAllResults = () => props.testResults;
    const getDisplayedResults = () => data.displayedResults;
    const getFilteredResults = () => data.filteredResults;

    function loadMoreResults(loadDisplayed: boolean) {
      const loadBy = (loadDisplayed && data.currentlyDisplayed > 5)
        ? data.currentlyDisplayed
        : data.displayMoreTestResultsBy;
      const newData = getFilteredResults().slice(
        0,
        getDisplayedResults().length + loadBy,
      );
      data.displayedResults = newData;

      if (getDisplayedResults().length >= getFilteredResults().length) {
        data.isLoadMoreButtonActive = false;
      }
    }

    function resetTableData(loadDisplayed: boolean) {
      data.currentlyDisplayed = data.displayedResults.length;
      data.displayedResults = [];
      loadMoreResults(loadDisplayed);
      data.isLoadMoreButtonActive = true;
    }

    function filterResults(
      status: boolean | undefined,
      dateFrom: number,
      dateTo: number,
    ) {
      const filterStatus = (run: ItestResult): boolean => {
        if (run.status === 'DONE_OK' && status) {
          return true;
        }
        if (run.status === 'DONE_FAIL' && !status) {
          return true;
        }
        if (status === undefined) {
          return true;
        }
        return false;
      };

      // toto by som mohol drbnut do jedneho

      const filterDateFrom = (run: ItestResult): boolean => {
        if (run.dateUx > dateFrom) {
          return true;
        }
        return false;
      };

      const filterDateTo = (run: ItestResult): boolean => {
        if (run.dateUx > dateTo) {
          return false;
        }
        return true;
      };

      data.filteredResults = getAllResults()
        .filter((run) => filterStatus(run))
        .filter((run) => filterDateFrom(run))
        .filter((run) => filterDateTo(run));
      resetTableData(true);
    }

    function displayImage(event: any) {
      Promise.resolve(api.getImageData(event.target.dataset.id))
        .then((imageData) => {
          const binaryData = imageData.data['testresult-report-content'];
          data.imageBinaryData = binaryData;
          // eslint-disable-next-line no-useless-concat
          const template = '<img src="data:image/gif;base64,'
            + `${data.imageBinaryData}`
            + '" alt="none" />';
          store.dispatch('toggleModal', template);
        })
        .catch((error) => {
          console.error(error);
          nf.error('Unexpected error happened while trying to download image');
        });
    }

    let sortDirectionDate = false;
    function sortByDate(event: any) {
      data.filteredResults = _.orderBy(
        getFilteredResults(),
        ['dateUx'],
        sortDirectionDate ? ['desc'] : ['asc'],
      );
      sortDirectionDate = !sortDirectionDate;
      resetTableData(true);
      event.target.classList.toggle('active');
    }

    let sortDirectionDuration = false;
    function sortByDuration(event: any) {
      data.filteredResults = _.orderBy(
        getFilteredResults(),
        ['duration'],
        sortDirectionDuration ? ['desc'] : ['asc'],
      );
      sortDirectionDuration = !sortDirectionDuration;
      resetTableData(true);
      event.target.classList.toggle('active');
    }

    onMounted(() => {
      data.filteredResults = getAllResults();
      loadMoreResults(false);
    });

    return {
      loadMoreResults,
      displayImage,
      ...toRefs(data),
      convertStatus,
      convertDate,
      convertMsToSec,
      getAllResults,
      filterResults,
      sortByDate,
      sortByDuration,
    };
  },
});
