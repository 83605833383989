<template>
  <div class="flex-row">
    <span class="title">Details of the test runs</span>

    <form class="table-filter">
      <app-select
        name="status"
        class-list="has-icon"
        input-id="status"
        :options="statusOptions"
        @change="changeStatus($event)"
      >
        <icon-arrow></icon-arrow>
      </app-select>

      <app-input
        input-id="date-from"
        :flatpickr="true"
        type="text"
        name="date-from"
        placeholder="From"
        classList="date has-icon"
        @change="changeDateFrom($event)"
      >
        <template v-slot:icon>
          <icon-calendar></icon-calendar>
        </template>
      </app-input>

      <app-input
        input-id="date-to"
        :flatpickr="true"
        type="text"
        name="date-to"
        placeholder="To"
        classList="date has-icon"
        @change="changeDateTo($event)"
      >
        <template v-slot:icon>
          <icon-calendar></icon-calendar>
        </template>
      </app-input>

      <button type="reset" @click="resetFilter()">Reset</button>
    </form>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, reactive } from 'vue';
import AppInput from '@/components/ui/native/AppInput.vue';
import AppSelect from '@/components/ui/native/AppSelect.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import IconCalendar from '@/components/icons/IconCalendar.vue';

type Data = {
  status: boolean | undefined;
  dateFrom: number;
  dateTo: number;
};

export default defineComponent({
  components: {
    AppInput,
    AppSelect,
    IconArrow,
    IconCalendar,
  },
  emits: ['filterResults'],
  setup(props, { emit }) {
    const data = reactive<Data>({
      status: undefined,
      dateFrom: 0,
      dateTo: Date.now(),
    });

    const statusOptions = [
      {
        value: 'all',
        text: 'Status',
      },
      {
        value: 'pass',
        text: 'Pass',
      },
      {
        value: 'fail',
        text: 'Fail',
      },
    ];

    function filterResults() {
      const { status, dateFrom, dateTo } = data;
      emit('filterResults', status, dateFrom, dateTo);
    }

    function resetFilter() {
      data.status = undefined;
      data.dateFrom = 0;
      data.dateTo = Date.now();
      filterResults();
    }

    function changeStatus(event: any) {
      if (event.target.value === 'pass') {
        data.status = true;
      } else if (event.target.value === 'fail') {
        data.status = false;
      } else if (event.target.value === 'all') {
        data.status = undefined;
      }
      filterResults();
    }

    function changeDateFrom(event: any) {
      // eslint-disable-next-line no-underscore-dangle
      data.dateFrom = event.target._flatpickr.latestSelectedDateObj.getTime() / 1000;
      filterResults();
    }

    function changeDateTo(event: any) {
      // eslint-disable-next-line no-underscore-dangle
      data.dateTo = event.target._flatpickr.latestSelectedDateObj.getTime() / 1000;
      filterResults();
    }

    return {
      filterResults,
      changeStatus,
      changeDateFrom,
      changeDateTo,
      resetFilter,
      statusOptions,
    };
  },
});
</script>

<style lang="scss">
.flex-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;

  .title {
    margin-left: 30px;

    img {
      vertical-align: middle;
      margin-left: 10px;
    }
  }
}

form.table-filter {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 655px;
  margin-left: 30px;

  @media (max-width: 1050px) {
    margin-left: 0px;
    padding: 30px 30px 0 30px;

    label,
    button {
      flex: 1 1 200px;
      margin: 5px;
    }
    input,
    button,
    select {
      max-width: 100%;
      width: 100%;
    }
  }

  button {
    background: transparent;
    font-size: 22px;
    font-family: "pm";
    color: #969ca2;
    margin-top: 0;
    cursor: pointer;
    padding: 10px;
    color: white;
    background: #807cf4;
    border-radius: 10px;
  }
}
</style>
